<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px'}" 
        :header="headerTitle" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="save()"
        @hide="hideDialog()" @keyup.esc="hideDialog()">

        <span class="p-field p-float-label" v-if="showDateField">
            <Calendar id="date" ref="firstEditField" name="x-betegseg-visit-datum" v-model="v$.vizit.date.$model" dateFormat="yy.mm.dd" 
                      :showIcon="true" :showButtonBar="true" :showOnFocus="true"
                      :class="{'p-invalid': v$.vizit.date.$invalid}" autocomplete="off"
                      :minDate="dateParam.datefrom" :maxDate="dateParam.dateto"
                      @change="calcVisitType" @date-select="calcVisitType"
            ></Calendar>
            <label for="date">Dátum</label>
            <small class="p-error" v-if="v$.vizit.date.$error">{{ errorMessages.required }}</small>
            <small class="p-error" v-if="errors.firstVisitDateMoreThanFiveMonths">5 hónap elteltével a beteg nem látogatható!</small>
        </span>

        <span class="p-field p-float-label" v-if="selectableVisittype">
            <Dropdown id="visittype_select" v-model="vizit.visittypeid" 
                :options="visittypeOptions" optionLabel="visitname" optionValue="id" placeholder=""
                required="true" :class="{'p-invalid': v$.vizit.visittype.$invalid}"
            />
            <label for="visittype_select" style="top: 1px; font-size: 12px;">Vizit típus</label>
            <small class="p-error" v-if="v$.vizit.visittype.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label" v-if="isAdmin">
            <AutoComplete id="selected_homecarer" v-model="v$.vizit.selected_homecarer.$model" 
                :suggestions="filteredUsers" @complete="searchUser($event)" field="name"
                :minLength="2" placeholder="Keresés 2 karaktertől..." autocomplete="new-order-homecarerid" 
                forceSelection required="true" :class="{'p-invalid': v$.vizit.selected_homecarer.$invalid}" 
            />
            <label for="selected_homecarer" style="top: 1px; font-size: 12px;">Beteglátogató</label>
            <small class="p-error" v-if="v$.vizit.selected_homecarer.$error">{{ errorMessages.required }}</small>
        </span>

        <span v-if="vizit.visittype.category == 'VIZIT_PROTKIVUL' && vizit.status!='J' && vizit.status!='T'">
            <label class="l-radio-label">Kérvényezés oka</label>
            <small class="p-error" v-if="v$.vizit.req_reason.$error">Kérjük, válasszon a lenti listából!</small>
            <span class="p-field-radiobutton" v-for="r in reqReasonOptions"  v-bind:key="r.value">
                <RadioButton :value="r.value" :id="'req_reason'+r.value" name="req_reason" v-model="v$.vizit.req_reason.$model" />
                <label :for="'req_reason'+r.value" v-html="r.label"></label>
            </span>
        </span>
        <span v-if="vizit.visittype.category == 'VIZIT_PROTKIVUL' && (vizit.status=='J' || vizit.status=='T')">
            <label class="l-radio-label">Kérvényezés oka</label>
            <div style="margin: 0 0 1.2rem 1rem;">
                {{ reqReasonLabel }}
            </div>
        </span>

        <span class="p-field p-float-label" v-if="editableReqNote">
            <Textarea id="description" v-model.lazy="vizit.req_note" rows="8" cols="20" style="resize: vertical; color: rgba(0, 0, 0, 0.87)"
                 :v="v$.vizit.req_note" @blur="v$.vizit.req_note.$touch"
                :class="{'p-invalid': v$.vizit.req_note.$error}"
            />
            <label for="description">Indoklás</label>
            <small class="p-error" v-if="v$.vizit.req_note.$error && v$.vizit.req_note.required.$invalid">{{ errorMessages.required }}</small>
            <small class="p-error" v-if="v$.vizit.req_note.$error && v$.vizit.req_note.minLength.$invalid">Kérem, ennél hosszabb leírást adjon meg! (Még {{ req_noteLengthMore }} karakter)</small>
        </span>
        <span v-if="vizit.visittype.category == 'VIZIT_PROTKIVUL' && (vizit.status=='J' || vizit.status=='T')">
            <label class="l-radio-label">Indoklás</label>
            <div style="margin: 0 0 1.2rem 1rem;">
                {{ vizit.req_note }}
            </div>
        </span>

        <span class="p-field" style="display:block" v-if="editableNote">
            <label class="l-fileupload-label">Vizitlap</label>
            <FileUpload name="files[]" mode="basic" class="p-d-inline p-mr-2" style="margin-left: 1rem" :chooseLabel="'Választ'" 
                :fileLimit="20971520" :key="fileUploadKey" accept=".xls,.xlsx,.png,.jpg,.pdf" :customUpload="true" @uploader="myFileUploader" :auto="true"
                :disabled="isUploading" :class="{'p-invalid': v$.uploadedFileName.$invalid}"
            />
            <span v-html="uploadedFileName ?? vizit.visitfile_name" v-show="!isUploading"></span>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem" v-show="isUploading"></i>
            <small class="p-error" v-if="v$.uploadedFileName.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label" v-if="editableNote">
            <Textarea id="description" v-model.lazy="vizit.note" rows="8" cols="20" style="resize: vertical; color: rgba(0, 0, 0, 0.87)"
                 :v="v$.vizit.note" @blur="v$.vizit.note.$touch"
                :class="{'p-invalid': v$.vizit.note.$error}"
            />
            <label for="description" v-html="noteLabel"></label>
            <small class="p-error" v-if="v$.vizit.note.$error && v$.vizit.note.required.$invalid">{{ errorMessages.required }}</small>
            <small class="p-error" v-if="v$.vizit.note.$error && v$.vizit.note.minLength.$invalid">Kérem, ennél hosszabb leírást adjon meg! (Még {{ noteLengthMore }} karakter)</small>
        </span>

        <template #footer>
            <div class="p-d-flex p-jc-between">
                <div class="p-as-end">
                    <Button :label="delButtonLabel" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-outlined p-button-warning" 
                        @click="deleteVisitConfirm" v-if="showDelButton"/>
                </div>
                <div>
                    <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
                    <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                        v-tooltip.bottom="'ESC'" tabindex="-1"
                        @click="hideDialog"/>
                    <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                        v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                        @click="save"/>
                </div>
            </div>
        </template>
    </Dialog>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import VisitService from '@/service/backend/visit.service'
import ParamService from '@/service/backend/param.service'
import UsersService from '@/service/backend/users.service'

import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { requiredIf, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import { store } from '@/store'

export default {
    props: ['show','dialogData'],
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
    data() {

        const megjelenit = reactive({})
        const vizit = reactive({})

        return {
            User: store.getters['user/User'],

            megjelenit,
            errorMessages,
            submitted: false,

            filteredUsers: null,

            vizit,

            tipusOptions: [{'code': 'N', 'value' :'Tápszer'}, {'code': 'A', 'value' :'Eszköz'}],
            visitStatusOptions: [{'code':'2', 'value':'Feldolgozás alatt'}, {'code':'5', 'value':'Teljesítve'}, {'code':'8', 'value':'Elutasítva'}],
            reqReasonOptions: [{'value': '1','label':'Nasogastricus szonda csere'}, 
                               {'value': '2','label':'Gastrotubus csere'},
                               {'value': '3','label':'Button csere'},
                               {'value': '4','label':'PEG alkatrész csere'},
                               {'value': '5','label':'Beteg orvosa kéri a vizitet'},
                               {'value': '9','label':'egyéb'}
                              ],
            visittypeOptions: [{'id':'0', 'visitname':'üres'}],
            dateParam: {datefrom: null, dateto: null},

            errors: {
                firstVisitDateMoreThanFiveMonths: false
            },

            fileUploadKey: 0,
            uploadedFileId: null,
            uploadedFileName: null,
            isUploading: false,

        }
    },
    validations() {

        const reqNoteMinLength = (this.editableReqNote) ? 8 : 0
        const noteMinLength = (this.editableNote) ? 45 : 0

        return {
            vizit: {
                id: { },
                req_reason: { required: requiredIf(function () {
                            return this.vizit.visittype.category == 'VIZIT_PROTKIVUL'
                    }) },
                date: { required: requiredIf(function () {
                            return this.vizit.visittype.category != 'VIZIT_PROTKIVUL' || this.vizit.status == 'J'
                    }) },
                selected_homecarer: { required: requiredIf(function () {
                            return this.isAdmin
                        }) }, 
                req_note: { 
                        required: requiredIf(function () {
                            return this.editableReqNote
                        }), 
                        minLength: minLength(reqNoteMinLength),
                    },
                note: { 
                        required: requiredIf(function () {
                            return this.editableNote
                        }),
                        minLength: minLength(noteMinLength)
                    },
                visittype: { required },
            },
            uploadedFileName: { required: requiredIf(function(){
                    return (((this.vizit.visittype.category != 'VIZIT_TAVOLI' && this.vizit.visittype.category != 'VIZIT_PROTKIVUL')
                            || (this.vizit.visittype.category == 'VIZIT_PROTKIVUL' && (this.vizit.status=='J' || this.vizit.status=='T')))
                        &&
                            this.vizit.visitfile_name === null
                        &&
                            this.$dayjs(this.vizit.date).format('YYYYMMDD') > '20220830' )
                }) 
            }
        }

    },

    visitService: null,
    paramService: null,
    usersService: null,

	created() {
        this.visitService = new VisitService()
		this.usersService = new UsersService()
        this.megjelenit = false
        this.vizit.visittype = {visitname: ""}
	},

    mounted() {
        if(this.User.role != 'ADMIN'){
            try{
                this.paramService = new ParamService()
                this.paramService.getParam() 
                .then( result => {
                    this.dateParam.datefrom = this.$dayjs(result[0].datefrom,"YYYY.MM.DD").toDate()
                    this.dateParam.dateto = this.$dayjs(result[0].dateto,"YYYY.MM.DD").toDate()
                })
            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt a paraméter betöltés közben', life: 3000})
            }
        }
    },

    methods: {
        dialogShow() {
            this.vizit.saved = false
            this.submitted = false
            this.errors.firstVisitDateMoreThanFiveMonths = false
            this.uploadedFileId = null
            this.uploadedFileName = null
            this.v$.$reset()

        },
        hideDialog() {
            this.megjelenit = false
            this.$emit('update:show', false)
        },
        searchUser(event) {
            this.usersService.getUsersForSelect({filters: event.query })
            .then( result => {
                this.filteredUsers = result
            })
        },
        async save() {
            this.v$.$touch()
            if (!this.v$.$invalid && !this.submitted) {
                this.submitted = true
                try {

                    if(!this.selectableVisittype){
                        this.vizit.visittypeid = this.vizit.visittype.id
                    }

                    // TODO inkább custom vuelidate kellene... 
                    if(!this.isAdmin && this.vizit.visittype.category != 'VIZIT_PROTKIVUL' && this.isMoreThanFiveMonth(this.vizit.date)){
                        this.errors.firstVisitDateMoreThanFiveMonths = true
                        throw new Error('VizitIsMoreThanFiveMonth');
                    }else{
                        this.errors.firstVisitDateMoreThanFiveMonths = false
                    }

                    if(typeof(this.vizit.date) == "object"){
                        this.vizit.evdate1 = this.$dayjs(this.vizit.date).format('YYYY.MM.DD')
                    }else
                    if(this.vizit.visittype.category == 'VIZIT_PROTKIVUL' && this.vizit.status == 'K'){
                        this.vizit.evdate1 = this.$dayjs().format('YYYY.MM.DD')
                    }else{
                        this.vizit.evdate1 = this.vizit.date
                    }
                    
                    if(this.vizit.visittype.category == 'VIZIT_PROTKIVUL' && this.vizit.status == 'J'){
                        this.vizit.status = 'T'
                    }

                    this.vizit.visit_status = this.vizit.status

                    if(this.isAdmin){
                        this.vizit.userid = this.vizit.selected_homecarer.id
                        this.vizit.homecarer = this.vizit.selected_homecarer.name
                    }

                    let new_visit = {
                        'userid' :      this.vizit.userid,
                        'customerid' :  this.vizit.customerid,
                        'visit_status': this.vizit.visit_status,
                        'visittypeid' : this.vizit.visittypeid,
                        'visitcost' :   this.vizit.visitcost,
                        'evdate1' :     this.vizit.evdate1,
                        'note' :        this.vizit.note
                    }
                    if(this.notEmpty(this.vizit.req_reason)) new_visit.req_reason = this.vizit.req_reason
                    if(this.notEmpty(this.vizit.req_note))   new_visit.req_note   = this.vizit.req_note

                    if(this.notEmpty(this.uploadedFileId)){
                        this.vizit.visitfile_id = this.uploadedFileId
                        new_visit.visitfile_id = this.uploadedFileId
                        this.vizit.visitfile_name = this.uploadedFileName
                    }

                    new_visit.id = this.vizit.id

                    if(this.vizit.id < 200000000){
                        
                        await this.visitService.updateVisit(new_visit)
                        this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Vizit módosítva!', life: 3000})

                    }else{

                        if(!this.isAdmin){
                            this.vizit.userid = this.User.id 
                            this.vizit.homecarer = this.User.name 
                            new_visit.userid = this.vizit.userid
                        }

                        this.vizit.id = await this.visitService.newVisit(new_visit)
                        this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Vizit leadva!', life: 3000})
                    }

                    this.vizit.date = this.vizit.evdate1

                    this.vizit.saved = true
                    this.$emit('update:dialogData', this.vizit)

                    this.hideDialog()

                } catch (error) {
                    //console.log(error)
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a rögzítésnél!', life: 3000})
                }
                this.submitted = false
            }
        },
        deleteVisitConfirm() {
            this.$confirm.require({
                message: 'Biztos benne?',
                header: this.delButtonLabel,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.deleteVisit()
                }
            });        
        },
        async deleteVisit() {
            // Ez egyelőre nem működik, nem lehet törölni
            this.submitted = true
            try{
                await this.visitService.deleteVisit(this.vizit.id)
                this.vizit.deleted = true
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Vizit törölve!', life: 3000})
                this.$emit('update:dialogData', this.vizit)
                this.hideDialog()
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a törlésnél!', life: 3000})
            }
            this.submitted = false
        },
        isMoreThanFiveMonth(day) {
            const first_date = this.$dayjs(this.vizit.firstvisitdate) 
            const day_before = this.$dayjs(day).subtract(5, 'months')
            return day_before > first_date
        },
        notEmpty(val) {
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined
        },
        calcVisitType() {
            const val = this.vizit.date
            if(typeof(val) == "string" && !(val.length==10 && this.$dayjs(val,'YYYY.MM.DD',true).isValid())){
                this.vizit.date = null
            }else
            if(this.vizit.visittype.category == 'VIZIT_IDOSZAK_1' || this.vizit.visittype.category == 'VIZIT_IDOSZAK_2'){
                if(this.isFirstVisitMonth)
                    this.vizit.visittypeid = this.vizit.visittype1.id
                else
                    this.vizit.visittypeid = this.vizit.visittype2.id
            }
        },
        async myFileUploader(event) {

            this.isUploading = true

            if(!this.notEmpty(this.vizit.id)) // || this.patient.id > 200000000 ) // miért kellene újat adni, ha már van temporary? így nemtudjuk törölni a temporary-kat
                this.vizit.id = '2000' + Math.floor(Math.random() * (90000) + 10000) // Lehet, hogy ez mindegy... 

            try{
                const uploadedFile = await this.visitService.uploadVisitFile(this.vizit.id, event.files[0])
                //this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Feltöltve!', life: 3000})
                this.uploadedFileId = uploadedFile.id
                this.uploadedFileName = uploadedFile.filename

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Valami hiba történt!", life: 3000})
                console.log(e)
                this.uploadedFileId = null
                this.uploadedFileName = null
            }

            this.isUploading = false
            this.fileUploadKey++

        },
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        },
        dialogData: function(v) {
            if(this.show){
                this.vizit = JSON.parse(JSON.stringify(v));
                this.vizit.visitcost = this.vizit.visittype.visitcost
                this.vizit.selected_homecarer = this.vizit.userid ? {id: this.vizit.userid, name: this.vizit.homecarer} : null
                
                if(!this.vizit.status){                    
                    if(this.vizit.visittype.category == 'VIZIT_PROTKIVUL')
                        this.vizit.status = 'K'
                    else
                        this.vizit.status = 'T'
                }
                if(this.vizit.visittype.category == 'VIZIT_PROTKIVUL' && this.vizit.status == 'J')
                    this.vizit.date = null
                
                if(this.selectableVisittype)
                    this.visittypeOptions = [this.vizit.visittype1, this.vizit.visittype2]
            }
        },
        'vizit.visittypeid': function(val) {
            if(this.selectableVisittype && val != undefined && val != this.vizit.visittype.id){
                this.vizit.visittype = this.visittypeOptions.find(v => v.id == val)
                this.vizit.visitcost = this.vizit.visittype.visitcost
            }
        }
    },
    computed: {
        isAdmin: function(){
            return this.User.role=='ADMIN';
        },
        headerTitle: function(){
            let ht = ""
            if(this.selectableVisittype)
                ht = "Vizit"
            else
                ht = this.vizit.visittype.visitname

            if(this.vizit.id < 200000000) 
                ht = ht + " (módosítás)"

            return ht
        },
        isFirstVisitMonth: function() {
            const first_date = (this.notEmpty(this.vizit.firstvisitdate)) ? this.$dayjs(this.vizit.firstvisitdate,'YYYY.MM.DD') : this.today
            const today_before = this.$dayjs(this.vizit.date).subtract(31, 'days')

            return today_before < first_date
        },
        noteLabel: function(){
            switch(this.vizit.visittype.category){
                case "VIZIT_TAVOLI":
                    return "Elhangzottak tartalma"
                default:
                    return "Leírás"
            }
        },
        showDelButton: function(){
            if( this.User.role=='ADMIN' || 
                (this.User.role=='CARER' && this.vizit.visittype.category == 'VIZIT_PROTKIVUL' && this.vizit.status=='K') ) 
            {
                return true
            }else
                return false
        },
        delButtonLabel: function(){
            return "Töröl"
        },
        noteLengthMore: function(){
            return 45 - this.vizit.note.length
        },
        req_noteLengthMore: function(){
            return 15 - this.vizit.req_note.length
        },
        showDateField: function(){
            return this.vizit.visittype.category != 'VIZIT_PROTKIVUL' || this.vizit.status == 'J' || this.vizit.status == 'T' // Jóváhagyva, Teljesítve
        },
        reqReasonLabel: function(){
            return this.reqReasonOptions.find(v => v.value == this.vizit.req_reason).label
        },
        editableReqNote: function(){
            return this.vizit.visittype.category == 'VIZIT_PROTKIVUL' && (this.vizit.status=='' || this.vizit.status=='K')
        },
        editableNote: function(){
            return this.vizit.visittype.category != 'VIZIT_PROTKIVUL' || this.vizit.status=='J' || this.vizit.status=='T'
        },
        selectableVisittype: function(){
            return this.isAdmin && (this.vizit.visittype.category == 'VIZIT_IDOSZAK_1' || this.vizit.visittype.category == 'VIZIT_IDOSZAK_2')
        }
    }
}
</script>

<style scoped>

.l-fileupload-label {
    margin-bottom: 0.6rem;
    display: block;    
    margin-left: 1rem;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

</style>
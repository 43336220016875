<template>
    <Dialog id="dialog" v-model:visible="megjelenit" style="width: 450px; margin-top: 16px" :style="(allapot.setKcal) ? {'opacity': '0'} : {'opacity': '1'}"
        :header="dialogName" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="saveState()" 
        @hide="hideDialog()" @keyup.esc="hideDialog()">

        <span class="p-field p-float-label">
            <Calendar id="datefrom" ref="firstEditField" name="x-betegseg-status-datum" v-model="v$.allapot.date.$model" dateFormat="yy.mm.dd" 
                      :showIcon="true" :showButtonBar="true" :showOnFocus="true"
                      :class="{'p-invalid': v$.allapot.date.$invalid}" autocomplete="off"
            ></Calendar>
            <label for="datefrom">Dátum</label>
            <small class="p-error" v-if="v$.allapot.date.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label" v-if="isAdmin">
            <AutoComplete id="selected_homecarer" v-model="v$.allapot.selected_homecarer.$model" 
                :suggestions="filteredUsers" @complete="searchUser($event)" field="name"
                :minLength="2" placeholder="Keresés 2 karaktertől..." autocomplete="new-order-homecarerid" 
                forceSelection required="true" :class="{'p-invalid': v$.allapot.selected_homecarer.$invalid}" 
            />
            <label for="selected_homecarer" style="top: 1px; font-size: 12px;">Beteglátogató</label>
            <small class="p-error" v-if="v$.allapot.selected_homecarer.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-model="v$.allapot.sonda.$model" :options="sondaOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="360px"
                required="true" :class="{'p-invalid': v$.allapot.sonda.$invalid}"
            />
            <label>Szonda behelyezés</label>
            <small class="p-invalid" v-if="v$.allapot.sonda.$error">{{ errorMessages.required }}</small>
        </span>
        <!-- TODO edit esetén lehetne mellette egy gomb, hogy előző érték -->

        <span class="p-field p-float-label">
            <Dropdown v-model="v$.allapot.sondatype.$model" :options="sondatypeOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="300px"
                required="true" :class="{'p-invalid': v$.allapot.sondatype.$invalid}"
            />
            <label>Szonda típus</label>
            <small class="p-invalid" v-if="v$.allapot.sondatype.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-model="v$.allapot.feedtype.$model" :options="feedtypeOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="300px"
                required="true" :class="{'p-invalid': v$.allapot.feedtype.$invalid}"
            />
            <label>Táplálás módja</label>
            <small class="p-invalid" v-if="v$.allapot.feedtype.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-model="v$.allapot.cond.$model" :options="condOptions" optionLabel="name" optionValue="id" placeholder=""
                required="true" :class="{'p-invalid': v$.allapot.cond.$invalid}"
            />
            <label>Állapot</label>
            <small class="p-invalid" v-if="v$.allapot.cond.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-model="v$.allapot.activity.$model" :options="activityOptions" optionLabel="name" optionValue="id" placeholder=""
                required="true" :class="{'p-invalid': v$.allapot.activity.$invalid}"
            />
            <label>Aktivitás</label>
            <small class="p-invalid" v-if="v$.allapot.activity.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label p-d-inline-block" style="width: 50%;">
            <InputNumber v-model="v$.allapot.weight.$model" :maxFractionDigits="2" :min="0" suffix=" kg"
                required="true" :class="{'p-invalid': v$.allapot.weight.$invalid}" autocomplete="allapot-weight"
            />
            <label>Súly</label>
            <small class="p-error" v-if="v$.allapot.weight.$error">{{ errorMessages.required }}</small>
            <small v-if="v$.allapot.height.$error" style="color:white">.</small>
        </span>

        <span class="p-field p-float-label p-d-inline-block" style="width: 50%;">
            <InputNumber v-model="v$.allapot.height.$model" :maxFractionDigits="1" :min="0" suffix=" cm"
                required="true" :class="{'p-invalid': v$.allapot.height.$invalid}" autocomplete="allapot-height"
            />
            <label>Magasság</label>
            <small class="p-error" v-if="v$.allapot.height.$error">{{ errorMessages.required }}</small>
            <small v-if="v$.allapot.weight.$error" style="color:white">.</small>
        </span>

        <span class="p-field p-float-label p-d-inline-block" style="width: 50%;" v-tooltip.bottom="'Számított...'">
            <InputNumber v-model="allapot.kcal_day" :maxFractionDigits="0" suffix=" kcal"
                disabled
            />
            <label>Energiaszükséglet / nap</label>
        </span>

        <span class="p-field p-float-label p-d-inline-block" style="width: 50%;" v-tooltip.bottom="'Számított...'">
            <InputNumber v-model="allapot.kcal_month" :maxFractionDigits="0" suffix=" kcal"
                disabled title="Számított..."
            />
            <label>Energiaszükséglet / hó</label>
        </span>
        
        <span class="p-field p-float-label p-d-inline-block" style="width: 50%;">
            <InputNumber v-model="v$.allapot.arm.$model" :maxFractionDigits="1" :min="0" suffix=" cm"
                required="true" :class="{'p-invalid': v$.allapot.arm.$invalid}" autocomplete="allapot-arm"
            />
            <label>Kar körfogat</label>
            <small class="p-error" v-if="v$.allapot.arm.$error">{{ errorMessages.required }}</small>
            <small v-if="v$.allapot.skin.$error" style="color:white">.</small>
        </span>

        <span class="p-field p-float-label p-d-inline-block" style="width: 50%;">
            <InputNumber v-model="v$.allapot.skin.$model" :maxFractionDigits="0" :min="0" suffix=" mm"
                required="true" :class="{'p-invalid': v$.allapot.skin.$invalid}" autocomplete="allapot-skin"
            />
            <label>Bőrredő</label>
            <small class="p-error" v-if="v$.allapot.skin.$error">{{ errorMessages.required }}</small>
            <small v-if="v$.allapot.arm.$error" style="color:white">.</small>
        </span>

        <span class="p-field p-float-label">
            <Textarea v-model="allapot.note" rows="4" cols="20" style="resize: vertical"
            />
            <label>Megjegyzés</label>
        </span>
        <span class="p-field" v-if="allapot.id">
            <label>Rögzítve:</label>
            <span class="p-ml-2">{{ allapot.createdbyfn }} - {{ allapot.createdat }}
            </span>
        </span>


        <template #footer>
            <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
            <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                v-tooltip.bottom="'ESC'" tabindex="-1"
                @click="hideDialog"/>
            <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                v-if="isAdmin || allapot.evtype!=='2'"
                v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                @click="saveState"/>
        </template>
    </Dialog>
</template>

<script>
import PatientService from '@/service/backend/patient.service'
import UsersService from '@/service/backend/users.service'
//import Vue from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import { store } from '@/store'

export default {
    props: ['show','dialogData', 'birthdate'],

    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },

    data() {
        const megjelenit = reactive({})
        const allapot = reactive({})
        
        return {
            User: store.getters['user/User'],
            megjelenit,
            allapot,

            errorMessages,

            submitted: false,

            filteredSocHomes: null,
            filteredUsers: null,

            sondaOptions: [{id:'1',name:'nasogastricus szonda'},{id:'2',name:'nasojejunalis szonda'},{id:'3',name:'endoscopos gastrostoma'},{id:'4',name:'sebészi gastrostoma'}
                          ,{id:'5',name:'sebészi jejunostoma'},{id:'6',name:'endoscopos jejunostoma'},{id:'E',name:'egyéb'}],
            sondatypeOptions: [{id:'1',name:'nasogastricus szonda'},{id:'2',name:'nasojejunalis szonda'},{id:'3',name:'PEG'},{id:'4',name:'PEJ'}
                          ,{id:'5',name:'gastrotubus'},{id:'6',name:'button'},{id:'7',name:'katéter'},{id:'8',name:'jejunokath'},{id:'E',name:'egyéb'}],
            feedtypeOptions: [{id:'B',name:'bólus'},{id:'G',name:'gravitációs szerelék'},{id:'P',name:'pumpa'},{id:'E',name:'szájon át étkezik'}],
            condOptions: [{id:'A',name:'teljes ápolásra szorul'},{id:'S',name:'segítségre szorul'},{id:'O',name:'önellátó'}],
            activityOptions: [{id:'J',name:'fennjáró beteg',energy:25},{id:'F',name:'fekvő beteg',energy:20}],


        }
    },
    validations: {
        allapot: {
            date: { required },
            selected_homecarer: { required: requiredIf(function () {
                        return this.isAdmin
                    }) }, 
            sonda: { required },
            sondatype: { required },
            feedtype: { required },
            cond: { required },
            activity: { required },
            height: { required },
            weight: { required },
            arm: { required },
            skin: { required },
        },
    },
	patientService: null,
    usersService: null,
	created() {
        this.patientService = new PatientService()
		this.usersService = new UsersService()
        this.megjelenit = false
	},
    mounted() {
        this.allapot.saved = false
    },
    methods: {
        dialogShow() {
            let v = JSON.parse(JSON.stringify(this.dialogData));
            if(v.id){

                v.weight = eval(v.weight)
                v.height = eval(v.height)
                v.arm = eval(v.arm)
                v.skin = eval(v.skin)
                if(v.evtype==0 || v.evtype==1){
                    if(v.weight === 0) v.weight = null
                    if(v.height === 0) v.height = null
                    if(v.arm === 0)    v.arm = null
                    if(v.skin === 0)   v.skin = null
                }

                if(v.activity!=='' && v.activity!=='J' && v.activity!=='F')
                    if(v.activity==='C' || v.activity==='N')
                        v.activity = 'J'
                    else
                        v.activity = 'F'
            }
            v.selected_homecarer = v.homecarer_id ? {id: v.homecarer_id, name: v.homecarer} : null
            this.allapot = v
            this.v$.$reset()
            this.$refs.firstEditField.$el.focus()

            if(this.allapot.setKcal){
                const saveWithCalcFunction = async () => {
                    await this.calcAge()
                    await this.saveState()
                    delete this.allapot.setKcal
                    this.allapot.saved = true
                    this.$emit('update:dialogData', this.allapot)
                    this.hideDialog()
                }
                saveWithCalcFunction()
            }

        },
        hideDialog() {
            this.$emit('update:show', false)
        },
        async saveState() {
            if(this.allapot.evtype === '2' && !this.isAdmin) return;

            this.v$.allapot.$touch()
            if (!this.v$.allapot.$invalid) {
                await this.save()
            }
        },
        async save() {

            this.submitted = true

            try{

                if(typeof(this.allapot.date) == "object"){
                    this.allapot.date = this.$dayjs(this.allapot.date.toString()).format('YYYY.MM.DD')
                }
                this.allapot.evdate1 = this.allapot.date
                if(this.isAdmin){
                    this.allapot.homecarer_id = this.allapot.selected_homecarer.id
                    this.allapot.homecarer = this.allapot.selected_homecarer.name
                }

                if (this.allapot.id) {
                    this.allapot.id = await this.patientService.updateStatus(this.allapot)

                    if(this.allapot.setKcal){
                        this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Kalória-adatok beállítva!', life: 9000})
                        delete this.allapot.setKcal
                    }else{
                        this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                    }
                    
                }
                else {
                    this.allapot.evtype = '5'
                    this.allapot.id = await this.patientService.newStatus(this.allapot)
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                }

                this.allapot.saved = true
                //this.allapot.updated = this.$dayjs().format('YYYY.MM.DD HH:mm:ss')

                this.$emit('update:dialogData', this.allapot)

                this.hideDialog()
                
            }catch(e){
                // TODO server oldali hiba kiírása?
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }

            this.submitted = false

        },
        calcAge: async function(bd) {
            // TODO mixin? -be tenni
            var today = this.$dayjs();
            var birthDate = this.$dayjs(bd);
            var age = today.year() - birthDate.year();
            var m = today.month() - birthDate.month();
            if (m < 0 || (m === 0 && today.date() < birthDate.date())) 
            {
                age--;
            }
            return age;

        },
        calcKcal() {
            if(this.allapot.weight){

                let age = false
                if(this.$dayjs(this.birthdate).isValid()){
                    age = this.calcAge(this.birthdate.replaceAll(".", "-"))
                }

                if(age && age<=6){
                    /*  Gyermek
                        Testtömeg:	kcal/kg
                        0-9 kg	100 kcal/ kg
                        9,1-14 kg	95 kcal/kg
                        14,1-20 kg	80 kcal/kg
                        21-40 kg	70 kcal/kg
                    */
                    const kcals = [{min: 0, max: 9, e: 100},
                                   {min: 9, max: 14, e: 95},
                                   {min: 14, max: 20, e: 80},
                                   {min: 20, max: 40, e: 70}]
                    const a = kcals.find(x => (this.allapot.weight > x.min && this.allapot.weight <= x.max))
                    if(a){
                        this.allapot.kcal_day = a.e * this.allapot.weight
                        this.allapot.kcal_month = this.allapot.kcal_day * 30
                        return
                    }

                }else
                if(this.allapot.activity){
                    const a = this.activityOptions.find(x => this.allapot.activity === x.id)
                    if(a){
                        this.allapot.kcal_day = a.energy * this.allapot.weight
                        this.allapot.kcal_month = this.allapot.kcal_day * 30
                        return
                    }
                }
            } 
            this.allapot.kcal_day = null
            this.allapot.kcal_month = null


        },
        searchUser(event) {
            this.usersService.getUsersForSelect({filters: event.query })
            .then( result => {
                this.filteredUsers = result
            })
        },
    },
    computed: {
        isAdmin: function(){
            return this.User.role=='ADMIN'
        },
        dialogName: function(){
            if(this.allapot.id)
                return 'Állapot szerkesztés'
            else
                return 'Állapot felvitel'
        }
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        },
        'allapot.weight': function() {
            this.calcKcal()
        },
        'allapot.activity': function() {
            this.calcKcal()
        }
    }
}
</script>

<style>

.p-disabled, .p-component:disabled {
    opacity: 0.88;
}

</style>

<style scoped>


</style>
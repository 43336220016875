<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px', 'margin-top': '16px'}" 
        header="Betegség" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="saveState()" 
        @hide="hideDialog()" @keyup.esc="hideDialog()">

        <span class="p-field p-float-label">
            <Calendar id="datefrom" ref="firstEditField" name="x-betegseg-visit-datum" v-model="v$.betegseg.date.$model" dateFormat="yy.mm.dd" 
                      :showIcon="true" :showButtonBar="true" :showOnFocus="true"
                      :class="{'p-invalid': v$.betegseg.date.$invalid}" autocomplete="off"
                      :minDate="dateParam.datefrom" :maxDate="dateParam.dateto"
            ></Calendar>
            <label for="datefrom">Vizit dátuma</label>
            <small class="p-invalid" v-for="e of v$.betegseg.date.$errors" :key="e.$uid">{{ e.$message }}</small>
        </span>

        <div class="p-field">
            <SelectButton v-model="v$.betegseg.illtype.$model" required="true" :class="{'p-invalid': v$.betegseg.illtype.$invalid }" 
                :options="optionsType" optionLabel="value" optionValue="code"
            />
            <small class="p-invalid" v-for="e of v$.betegseg.illtype.$errors" :key="e.$uid">{{ e.$message }}</small>
        </div>

        <span class="p-field p-float-label">
            <AutoComplete v-model="betegseg.selectedBNO" title="Keresés 2 karaktertől..." autocomplete="new-illness-bno" 
                :suggestions="filteredBNOs" @complete="searchBNO($event)" :delay="600" field="bno" :minLength="2" forceSelection
                :class="{'p-invalid': v$.betegseg.bno.$invalid}" required="true" placeholder="Keresés 2 karaktertől..."
                @item-select="bnoSelected(betegseg.selectedBNO)" @blur="bnoSelected(betegseg.selectedBNO)">
                <template #item="slotProps">
                    <div>{{slotProps.item.bno}} - {{slotProps.item.illname}}</div>
                </template>
            </AutoComplete>
            <label style="top: 1px; font-size: 12px;">BNO kód</label>
            <small class="p-invalid" v-for="e of v$.betegseg.bno.$errors" :key="e.$uid">{{ e.$message }}</small>
        </span>

        <span class="p-field">
            <span class="p-d-block" style="height: 2rem; margin-bottom: 1.6rem; margin-left: 1rem;">{{IllnessName}}</span>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-model="v$.betegseg.illcat.$model" :options="optionsIllcat" optionLabel="illname" optionValue="bno" placeholder="" scrollHeight="200px"
                required="true" :class="{'p-invalid': v$.betegseg.illcat.$invalid}"
            />
            <label>Betegség kategória</label>
            <small class="p-invalid" v-for="e of v$.betegseg.illcat.$errors" :key="e.$uid">{{ e.$message }}</small>
        </span>

        <span class="p-field p-float-label">
            <Textarea v-model="betegseg.note" rows="4" cols="20" style="resize: vertical"
            />
            <label>Megjegyzés</label>
        </span>


        <template #footer>
            <div class="p-d-flex p-jc-between">
                <div class="p-as-end">
                    <Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-outlined p-button-warning" 
                        @click="deleteConfirm($event)" v-if="betegseg.id"/>
                </div>
                <div>
                    <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
                    <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                        v-tooltip.bottom="'ESC'" tabindex="-1"
                        @click="hideDialog"/>
                    <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                        v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                        @click="saveState"/>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
import PatientService from '@/service/backend/patient.service'
import ParamService from '@/service/backend/param.service'
//import Vue from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import { store } from '@/store'

export default {
    props: ['show','dialogData'],

    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },

    data() {
        const megjelenit = reactive({})

        return {
            User: store.getters['user/User'],

            megjelenit,
            errorMessages,
            submitted: false,

            filteredBNOs: null,

            optionsType: [{'code': '1', 'value' :'Fő betegség'}, {'code': '2', 'value' :'Kísérő betegség'}],
            optionsIllcat: [],

            betegseg: { selectedBNO: null },

            dateParam: {datefrom: null, dateto: null},

        }
    },
    validations: {
        betegseg: {
            homecarer: { },
            date: { required },
            illtype: { required },
            bno: { required },
            illcat: { required }
        },
    },

	patientService: null,
    paramService: null,

	created() {
        this.patientService = new PatientService()
        this.megjelenit = false
        this.loadIllnessCategories()
	},
    mounted() {
        this.betegseg.saved = false
        if(this.User.role != 'ADMIN'){
            try{
                this.paramService = new ParamService()
                this.paramService.getParam() 
                .then( result => {
                    this.dateParam.datefrom = this.$dayjs(result[0].datefrom,"YYYY.MM.DD").toDate()
                    this.dateParam.dateto = this.$dayjs(result[0].dateto,"YYYY.MM.DD").toDate()
                })
            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt a paraméter betöltés közben', life: 3000})
            }
        }
    },
    methods: {
        dialogShow() {
            this.betegseg = JSON.parse(JSON.stringify(this.dialogData));
            if(this.betegseg.id){
                this.betegseg.date = new Date(this.betegseg.date)
                if(!this.betegseg.selectedBNO){
                    this.patientService.getBNOsForSelect({filters: this.betegseg.bno})
                    .then( result => {
                        this.betegseg.selectedBNO = result[0]
                    })
                }
            }
            this.v$.$reset()
            this.$refs.firstEditField.$el.focus()
        },
        hideDialog() {
            this.$emit('update:show', false)
        },
        saveState() {
            this.v$.betegseg.$touch()
            if (!this.v$.betegseg.$invalid) {
                this.save()
            }
        },
        async save() {

            this.submitted = true

            try{

                this.betegseg.evdate1 = this.$dayjs(this.betegseg.date).format('YYYY.MM.DD')

                if (this.betegseg.id) {
                    await this.patientService.updateIllness(this.betegseg)
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                }
                else {
                    this.betegseg.id = await this.patientService.newIllness(this.betegseg)
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                }

                this.betegseg.date = this.$dayjs(this.betegseg.date).format('YYYY.MM.DD')
                this.betegseg.illname = this.betegseg.selectedBNO.illname
                this.betegseg.saved = true

                this.$emit('update:dialogData', this.betegseg)

                this.hideDialog()
                
            }catch(e){
                // TODO server oldali hiba kiírása?
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }

            this.submitted = false

        },
        deleteConfirm(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Biztos benne?',
                header: 'Törlés',
                acceptClass: 'p-button-danger',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.delete()
                }
            });        
        },
        async delete() {
            this.submitted = true
            try{
                await this.patientService.deleteIllness(this.betegseg.id)
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Rendelés törölve!', life: 3000})

                this.betegseg.saved = false
                this.betegseg.deleted = true
                this.$emit('update:dialogData', this.betegseg)
                this.hideDialog()
            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a törlésnél!', life: 3000})
            }
            this.submitted = false
        },
        async loadIllnessCategories() {
            try{
                this.optionsIllcat = await this.patientService.getIllcatsForSelect()
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a kategóriák betöltésénél', life: 3000})
            }
        },
        searchBNO(event) {
            this.patientService.getBNOsForSelect({filters: event.query })
            .then( result => {
                this.filteredBNOs = result
            })
        },
        bnoSelected(bno) {
            if(bno != null && bno != undefined)
                this.betegseg.bno = bno.bno
            else
                this.betegseg.bno = null
        }
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        }
    },
    computed: {
        IllnessName: function() {
            if(this.betegseg.selectedBNO == null ||
               this.betegseg.selectedBNO.illname == null )
               return ''
            else
                return this.betegseg.selectedBNO.illname
        }
    }
}
</script>

<style>

.p-disabled, .p-component:disabled {
    opacity: 0.88;
}

</style>

<style scoped>


</style>
<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px', 'margin-top': '16px'}" 
        header="Szociális otthon" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="savePatient()" 
        @hide="hideDialog()" @keyup.esc="hideDialog()">
        <span class="p-field p-float-label">
            <InputText id="betegname" ref="firstEditField" v-model.trim="v$.beteg.name.$model"
                required="true" :class="{'p-invalid': v$.beteg.name.$invalid}" autocomplete="beteg-name"
            />
            <label for="fullname">Megnevezés</label>
            <small class="p-error" v-if="v$.beteg.name.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label" style="width: 18%; display: inline-block">
            <InputMask id="postcode" v-model="v$.beteg.postcode.$model" mask="9999"
                required="true" :class="{'p-invalid': v$.beteg.postcode.$invalid}" autocomplete="beteg-postcode"
            />
            <label for="postcode">IRSZ</label>
            <small class="p-error" v-if="v$.beteg.postcode.$error">Kötelező!</small>
        </span>
        <span class="p-field p-float-label" style="width: 82%; display: inline-block">
            <InputText id="city" v-model.trim="v$.beteg.city.$model"
                required="true" :class="{'p-invalid': v$.beteg.city.$invalid}" autocomplete="beteg-city"
            />
            <label for="city">Város</label>
            <small class="p-error" v-if="v$.beteg.city.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label">
            <InputText id="address1" v-model.trim.lazy="v$.beteg.address1.$model"
                required="true" :class="{'p-invalid': v$.beteg.address1.$invalid}" autocomplete="beteg-address1"
            />
            <label for="address1">Cím</label>
            <small class="p-error" v-if="v$.beteg.address1.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label">
            <InputText id="tel1" v-model.trim.lazy="v$.beteg.tel1.$model"
                required="true" :class="{'p-invalid': v$.beteg.tel1.$invalid}" autocomplete="beteg-tel1"
            />
            <label for="tel1">Telefonszám</label>
            <small class="p-error" v-if="v$.beteg.tel1.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label">
            <Textarea id="description" v-model="beteg.note" rows="3" cols="20" style="resize: vertical"
            />
            <label for="description">Megjegyzés</label>
        </span>
        <span class="p-field p-float-label">
            <InputText id="firstvisitdate" v-model.trim.lazy="beteg.firstvisitdate"
                required="true" autocomplete="beteg-firstvisitdate"
            />
            <label for="firstvisitdate">Első beteglátogatás dátuma</label>
        </span>

        <template #footer>
            <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
            <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                v-tooltip.bottom="'ESC'" tabindex="-1"
                @click="hideDialog"/>
            <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                @click="savePatient"/>
        </template>
    </Dialog>
</template>

<script>
import PatientService from '@/service/backend/patient.service'
//import Vue from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'

export default {
    props: ['show','dialogData'],

    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },

    data() {
        const megjelenit = reactive({})
        const beteg = reactive({})
        
        return {
            megjelenit,
            beteg,

            errorMessages,

            submitted: false,

            filteredSocHomes: null,

        }
    },
    validations: {
        beteg: {
            name: { required },
            city: { required },
            postcode: { required },
            address1: { required },
            tel1: { required }
        },
    },
	betegService: null,
	created() {
        this.betegService = new PatientService();
        this.megjelenit = false;
	},
    mounted() {
        this.beteg.saved = false
    },
    methods: {
        dialogShow() {
            this.$refs.firstEditField.$el.focus()
        },
        hideDialog() {
            this.$emit('update:show', false)
        },
        savePatient() {
            this.v$.beteg.$touch()
            if (!this.v$.beteg.$invalid) {
                this.save()
            }
        },
        async save() {

            this.submitted = true

            try{
                this.beteg.id = await this.betegService.updatePatient(this.beteg)

                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})

                this.beteg.saved = true
                this.beteg.updated = this.$dayjs().format('YYYY.MM.DD HH:mm:ss')

                this.$emit('update:dialogData', this.beteg)

                this.hideDialog()
                
            }catch(e){
                // TODO server oldali hiba kiírása?
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }

            this.submitted = false

        },
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        },
        dialogData: function(v) {
            if(this.show)
                this.beteg = v
        }
    }
}
</script>

<style>

</style>
<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px', 'margin-top': '16px'}" 
        header="Orvosok" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="save()"
        @hide="hideDialog()" @keyup.esc="hideDialog()">

        <span class="p-field p-float-label" style="margin-top: 1rem">
            <AutoComplete ref="firstEditField" v-model="v$.doctorE.selectedDoctor.$model" title="Keresés 2 karaktertől..." autocomplete="new-patient-doctor" 
                :suggestions="filteredDoctors" @complete="searchDoctor($event)" field="fullname" :minLength="2" forceSelection
                required="true" :class="{'p-invalid': v$.doctorE.$invalid }">
                <template #item="slotProps">
                    <div>{{slotProps.item.fullname}} - {{slotProps.item.prof2}}</div>
                </template>
            </AutoComplete>
            <label style="top: 1px; font-size: 12px;">Eszközbehelyező orvos</label>
            <small class="p-error" v-if="v$.doctorE.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label" style="margin-top: 1rem">
            <AutoComplete v-model="v$.doctorK.selectedDoctor.$model" title="Keresés 2 karaktertől..." autocomplete="new-patient-doctor" 
                :suggestions="filteredDoctors" @complete="searchDoctor($event)" field="fullname" :minLength="2" forceSelection
                required="true" :class="{'p-invalid': v$.doctorK.$invalid }">
                <!--@item-select=""-->
                <template #item="slotProps">
                    <div>{{slotProps.item.fullname}} - {{slotProps.item.prof2}}</div>
                </template>
            </AutoComplete>
            <label style="top: 1px; font-size: 12px;">Kezelőorvos</label>
            <small class="p-error" v-if="v$.doctorK.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label" style="margin-top: 1rem">
            <AutoComplete v-model="v$.doctorT.selectedDoctor.$model" title="Keresés 2 karaktertől..." autocomplete="new-patient-doctor" 
                :suggestions="filteredDoctors" @complete="searchDoctor($event)" field="fullname" :minLength="2" forceSelection
                required="true" :class="{'p-invalid': v$.doctorT.$invalid }">
                <!--@item-select=""-->
                <template #item="slotProps">
                    <div>{{slotProps.item.fullname}} - {{slotProps.item.prof2}}</div>
                </template>
            </AutoComplete>
            <label style="top: 1px; font-size: 12px;">Lejelentő</label>
            <small class="p-error" v-if="v$.doctorT.$error">{{ errorMessages.required }}</small>
        </span>

        <template #footer>
            <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                v-tooltip.bottom="'ESC'" tabindex="-1"
                @click="hideDialog"/>
            <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                @click="save"/>
        </template>
    </Dialog>
</template>

<script>
import PatientService from '@/service/backend/patient.service'
import DoctorService from '@/service/backend/doctor.service'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import { store } from '@/store';

export default {
    props: ['show','dialogData'],
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
    data() {

        const megjelenit = reactive({})

        return {
            User: store.getters['user/User'],

            megjelenit,
            submitted: false,
            errorMessages,

            doctorE: {}, doctorK: {}, doctorT: {},
            orvosok: { saved: null, doctor: [] },

            filteredDoctors: null, 

        }
    },
    validations: {
        doctorE: { selectedDoctor: { required } },
        doctorK: { selectedDoctor: { required } },
        doctorT: { selectedDoctor: { required } },
    },

    doctorService: null,
    patientService: null,

	created() {
        this.doctorService = new DoctorService()
        this.patientService = new PatientService()
        this.megjelenit = false
	},
    mounted() {
        //this.$refs.firstEditField.$el.focus()
    },

    methods: {
        dialogShow() {
            this.orvosok.saved = false
            this.submitted = false
                      
            this.doctorE = this.dialogData.doctor.find(d => d.type == 'E')
            if(this.doctorE != undefined){
                this.doctorE.selectedDoctor = { id: this.doctorE.doctorid, fullname: this.doctorE.name }
            }else
                this.doctorE = { selectedDoctor: null }

            this.doctorK = this.dialogData.doctor.find(d => d.type == 'K')
            if(this.doctorK != undefined){
                this.doctorK.selectedDoctor = { id: this.doctorK.doctorid, fullname: this.doctorK.name }
            }else
                this.doctorK = { selectedDoctor: null }

            this.doctorT = this.dialogData.doctor.find(d => d.type == 'T')
            if(this.doctorT != undefined){
                this.doctorT.selectedDoctor = { id: this.doctorT.doctorid, fullname: this.doctorT.name }
            }else
                this.doctorT = { selectedDoctor: null }

        },
        hideDialog() {
            this.megjelenit = false
            this.$emit('update:show', false)
        },
        searchDoctor(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.doctorService.getDoctorsForSelect({filters: event.query })
                .then( result => {
                    this.filteredDoctors = result
                })
            }, 600)
        },
        needsToUpdate(type){
            return (this['doctor'+type].doctorid == undefined && this['doctor'+type].selectedDoctor != null || 
                    this['doctor'+type].doctorid != undefined && this['doctor'+type].selectedDoctor == null || 
                    this['doctor'+type].doctorid != undefined && this['doctor'+type].selectedDoctor != null && this['doctor'+type].doctorid != this['doctor'+type].selectedDoctor.id)
        },
        async save() {
            this.v$.$touch()
            if (!this.v$.$invalid && !this.submitted) {
                this.submitted = true
                try {

                    let update = { customerid: this.dialogData.customerid, doctors: [] } // csak a változást küldjük el

                    if( this.needsToUpdate('E') )
                        update.doctors.push({ evtype: 'E', doctorid: (this.doctorE.selectedDoctor==null) ? null : this.doctorE.selectedDoctor.id })
                    if( this.needsToUpdate('K') )
                        update.doctors.push({ evtype: 'K', doctorid: (this.doctorK.selectedDoctor==null) ? null : this.doctorK.selectedDoctor.id })
                    if( this.needsToUpdate('T') )
                        update.doctors.push({ evtype: 'T', doctorid: (this.doctorT.selectedDoctor==null) ? null : this.doctorT.selectedDoctor.id })

                    if(update.doctors.length > 0){

                        const updateddoctors = await this.patientService.updateDoctor(update)
                        this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Orvosok módosítva!', life: 3000})

                        this.orvosok.saved = true
                        this.orvosok.doctor = updateddoctors.doctors
                        this.$emit('update:dialogData', this.orvosok)

                    }else{
                        this.$toast.add({severity:'warn', summary: '', detail: 'Nincs változás!', life: 3000})
                    }

                    this.hideDialog()

                } catch (error) {
                    console.log(error)
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a rögzítésnél!', life: 3000})
                }
            
                this.submitted = false
            }
        }
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        },
        dialogData: function(v) {
            if(this.show){
                this.orvosok = JSON.parse(JSON.stringify(v));
            }
        },
    },
    computed: {
    }
}
</script>

<style scoped>

</style>